import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {AuthService} from '../auth/services/auth.service'
import {Observable, of} from 'rxjs'
import {shareReplay, switchMap, take} from 'rxjs/operators'
import {HOSTNAME} from './hostname'

const API_ROOT = 'https://api.' + HOSTNAME + '/'

interface OptionHeaders {
  headers: {
    Authorization: string;
    'id-token': string;
    Accept: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private options: Observable<OptionHeaders>

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.options = authService.onUserSession.pipe(
      switchMap((session) => {
        console.log('session', session)
        return of({
          headers: {
            Authorization: 'Bearer ' + session.accessToken.jwtToken,
            'id-token': session.idToken.jwtToken,
            Accept: 'application/json',
          },
        })
      }),
      shareReplay(1),
    )
  }

  get<T>(path: string): Observable<T> {
    return this.options.pipe(
      take(1),
      switchMap((options) => this.httpClient.get<T>(API_ROOT + path, options)),
    )
  }

  delete<T>(path: string): Observable<T> {
    return this.options.pipe(
      take(1),
      switchMap((options) => this.httpClient.delete<T>(API_ROOT + path, options)),
    )
  }

  post<T, R>(path: string, payload: T): Observable<R> {
    return this.options.pipe(
      take(1),
      switchMap((options) => this.httpClient.post<R>(API_ROOT + path, payload, options)),
    )
  }

  put<T, R extends T>(path: string, payload: T): Observable<R> {
    return this.options.pipe(
      take(1),
      switchMap((options) => this.httpClient.put<R>(API_ROOT + path, payload, options)),
    )
  }

  patch<T, R extends T>(path: string, payload: T): Observable<R> {
    return this.options.pipe(
      take(1),
      switchMap((options) => this.httpClient.patch<R>(API_ROOT + path, payload, options)),
    )
  }

  postFile<R>(path: string, headers: object, fileToUpload: File): Observable<R> {
    return this.options.pipe(
      take(1),
      switchMap((options: OptionHeaders) => {
        const newHeaders = Object.assign({}, options.headers, headers)
        console.log(newHeaders)
        return this.httpClient.post<R>(API_ROOT + 'documents/' + path, fileToUpload, {
          headers: newHeaders,
        })
      }),
    )
  }

  getDocument(path: string, mimeType: string = 'application/pdf', version?: string): Observable<Blob> {
    return this.options.pipe(
      take(1),
      switchMap((options: OptionHeaders) => {
        let url = `${API_ROOT}documents/${path}`
        if (version) {
          url += `?version=${version}`
        }
        return this.httpClient.get(url, {
          headers: {
            Authorization: options.headers.Authorization,
            'id-token': options.headers['id-token'],
            Accept: mimeType,
          },
          responseType: 'blob',
        })
      }),
    )
  }

  head<T>(path: string): Observable<T> {
    return this.options.pipe(
      take(1),
      switchMap((options) => this.httpClient.head<T>(API_ROOT + path, options)),
    )
  }
}
