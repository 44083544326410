import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {SettingsResponse} from '../model/settings-responses'
import {HOSTNAME} from '../../services/hostname'

@Injectable()
export class AuthSettingsService {
  constructor(private httpClient: HttpClient) {
  }

  async loadSettings() {
    const settingsUrl = 'https://api.' + HOSTNAME + '/settings/authServers'
    return this.httpClient
      .get(settingsUrl, {
        headers: {
          Accept: 'application/json',
          Authorization: 'token',
          'id-token': 'token',
          token: 'token',
        },
      })
      .toPromise()
      .then((response) => {
        return response as SettingsResponse
      })
  }
}
