import {Component, OnInit} from '@angular/core'
import {CurrentUserService} from '../../services/current-user.service'
import {AuthService, UserSession} from '../services/auth.service'
import {ToastrService} from 'ngx-toastr'
import {ClipboardService} from 'ngx-clipboard'
import {faKey} from '@fortawesome/free-solid-svg-icons'
import {DevGuard} from '../../guards/dev-guard.service'

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  private session: UserSession;

  user: string = undefined;
  menuDisplayed = false;

  faKey = faKey;

  constructor(
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private clipboard: ClipboardService,
    private toast: ToastrService,
    public devGuard: DevGuard
  ) {}

  ngOnInit(): void {
    this.currentUserService.currentUser.subscribe((user) => {
      this.user = user?.name;
    });

    this.authService.onUserSession.subscribe((userSession) => {
      this.session = userSession;
    });
  }

  toggleMenu() {
    this.menuDisplayed = !this.menuDisplayed;
  }

  signOut() {
    this.authService.signOut();
  }

  saveDeveloperToken() {
    console.log('Saving developer token to clipboard');
    const data = {
      id: this.session.idToken.jwtToken,
      access: this.session.accessToken.jwtToken,
    };

    this.clipboard.copy(
      'const jwtTokens = ' + JSON.stringify(data, undefined, 2)
    );

    this.toast.info('JWT Tokens copied to the clipboard!');
  }
}
