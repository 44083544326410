import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {ServerEventsService} from './server-events.service'
import {ReactiveApiService} from './reactive.api.service'

const API_SLUG = 'data'

const EVENT_SOURCE = 'inspire.PlatformData'

@Injectable({
  providedIn: 'root',
})
export class DataService extends ReactiveApiService {
  constructor(
    public apiService: ApiService,
    public serverEventsService: ServerEventsService,
  ) {
    super(API_SLUG, EVENT_SOURCE, apiService, serverEventsService)
    this.serverEventsService.wss.subscribe(e => {
      console.log('Websocket Event:')
      console.dir(e)
    })
  }
}
