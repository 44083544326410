import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http'
import {FlexLayoutModule} from '@angular/flex-layout'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {ScrollingModule} from '@angular/cdk/scrolling'

import {NgbModule} from '@ng-bootstrap/ng-bootstrap'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'

import {LoggerModule, NgxLoggerLevel} from 'ngx-logger'

import {AppComponent} from './app.component'
import {AppRoutingModule} from './app-routing.module'
import {DashboardComponent} from './views/dashboard.component'

import {AuthModule} from './auth/auth.module'
import {PageNotFoundComponent} from './views/page-not-found/page-not-found.component'

import {TranslateLoader, TranslateModule} from '@ngx-translate/core'
import {TranslateHttpLoader} from '@ngx-translate/http-loader'
import {ClientLogosModule} from './shared/client-logos/client-logos.module'
import {ClipboardModule} from 'ngx-clipboard'
import {ToastrModule} from 'ngx-toastr'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'
import {NavigationModule} from './navigation/navigation.module'

import {ClientNavigationComponent} from './client/client-navigation/client-navigation.component'
import {InternalNavigationComponent} from './internal/internal-navigation/internal-navigation.component'

import {JwtInterceptor} from './interceptors/jwt.interceptor'
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown'

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient)
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    InternalNavigationComponent,
    ClientNavigationComponent,
    PageNotFoundComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,

    AppRoutingModule,

    HttpClientModule,

    FormsModule,
    ReactiveFormsModule,

    FontAwesomeModule,
    NgbModule,
    NgxDatatableModule,

    ScrollingModule,

    AuthModule,

    NgMultiSelectDropDownModule.forRoot(),

    LoggerModule.forRoot({
      enableSourceMaps: true,
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ClientLogosModule,
    ClipboardModule,
    ToastrModule.forRoot({
      maxOpened: 8,
      autoDismiss: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right',
    }),
    NavigationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
}
