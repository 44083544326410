import {Component, Input, OnInit} from '@angular/core'

@Component({
  selector: 'app-navbar-nav-item',
  styleUrls: ['./navbar-nav-item.component.scss'],
  templateUrl: './navbar-nav-item.component.html'
})
export class NavbarNavItemComponent implements OnInit {
  @Input() to: string;

  constructor() {}

  ngOnInit(): void {}
}
