import {Injectable} from '@angular/core'
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Observable} from 'rxjs'
import {ApiService} from '../services/api.service'
import {PermissionTokenService} from '../services/permission-token.service'


const jwt = require('jsonwebtoken')

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private apiService: ApiService,
    private permissionTokenService: PermissionTokenService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
    // FIXME disabled while testing permission matching
    // const path = JwtInterceptor.getEndpointPath(request.url)
    // if (!request.url.includes('inspire.direct')) {
    //   return next.handle(request)
    // }
    // return this.permissionTokenService.getPermissionsToken(path, request.method).pipe(
    //   flatMap(permissionToken => {
    //     const authReq = request.clone({
    //       headers: request.headers.append('token', permissionToken),
    //     })
    //     return next.handle(authReq)
    //   }),
    // )
  }

  private static getEndpointPath(url: string): string {
    if (url.startsWith('https://') || url.startsWith('http://')) {
      url = url.replace('https://', '').replace('http://', '')
    }
    return url.split('/').slice(1).join('/')
  }
}
