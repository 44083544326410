import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {UserDetails, UserPermission} from '../model/user'
import {map} from 'rxjs/operators'
import {AuthService} from '../auth/services/auth.service'

interface LegacyPermission extends UserPermission {
  clients?: string[]
  distributors?: string
}

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  currentUser: Observable<UserDetails | undefined> = this.authService.onUserSession
    .pipe(
      map(userDetails => {
        const session = userDetails
        const environment = userDetails?.environment
        let result: UserDetails = undefined

        if (session?.isValid()) {
          const email = session.idToken.payload.email
          const groups = session.idToken.payload['cognito:groups'] || []
          const permissions = JSON.parse(session.idToken.payload.permissions) as LegacyPermission
          const activeOrganisation = permissions.clients && permissions.clients.length === 1 ? permissions.clients[0] : undefined
          result = {
            name: session.idToken.payload.name,
            email,
            type: 'unknown',
            groups,
            clients: permissions.clients,
            organisation: activeOrganisation,
          }
          if (environment === 'Prod' || environment === 'Demo' || environment === 'Test') {
            result.type = 'inspire'
            // result.type = 'client'
          } else {
            if (permissions.clients.length === 1) {
              result.type = 'client'
              result.organisation = permissions.clients[0]
            } else {
              result.type = 'unknown'
              console.error('Unexpected user type: ')
              console.dir(session.idToken.payload)
              console.dir(permissions)
            }
          }
        }
        return result
      }),
    )

  constructor(private authService: AuthService) {
  }
}


