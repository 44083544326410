<ng-container *ngIf="!user">
  <app-login-screen></app-login-screen>
</ng-container>

<ng-container *ngIf="user" [ngSwitch]="userType">
  <app-internal-navigation *ngSwitchCase="'inspire'"></app-internal-navigation>
  <app-client *ngSwitchCase="'client'"></app-client>

  <app-navbar *ngSwitchDefault>
    <p>Unknown User Type - Accessed Denied</p>
    <app-user-menu></app-user-menu>
  </app-navbar>
</ng-container>

<div class="app-version" *ngIf="user && userType !== 'unknown'">
  <div class="environment">
    {{ environment }}
  </div>
  {{ 'HOME.BUILD_NUMBER' | translate }}: {{ appBuildNumber }}<br />
  {{ 'HOME.BUILD_DATE' | translate }}: {{ appBuildDateString }}<br />
  {{ 'HOME.VERSION' | translate }}: {{ appVersion.substring(0, 10) }}<br />
</div>

<!-- This is a React Portal to render dropdowns and datePickers into -->
<div id="dropdown-root"></div>
