import {Injectable} from '@angular/core'
import {webSocket, WebSocketSubject} from 'rxjs/webSocket'
import {AuthService, UserSession} from '../auth/services/auth.service'
import {HOSTNAME} from './hostname'

const WSS_ROOT = 'wss://ws.' + HOSTNAME + '/';

@Injectable({
  providedIn: 'root',
})
export class ServerEventsService {
  private session: UserSession;
  wss: WebSocketSubject<any>;

  constructor(private authService: AuthService) {
    this.wss = webSocket(WSS_ROOT);
    authService.onUserSession.subscribe((session: UserSession) => {
      this.session = session;
      this.connect();
    });
  }

  private disconnect() {
    if (this.wss) {
      this.wss.unsubscribe();
      this.wss.complete();
      this.wss = undefined;
    }
  }

  connect() {
    try {
      if (this.session && this.session.isValid()) {
        if (!this.wss) {
          this.wss.subscribe(
            (msg) => {
              // console.log('Message received on websocket')
              // console.dir(msg)
            },
            (err) => {
              console.log('WebSocket error: ');
              console.dir(err);
              this.wss = undefined;
            },
            () => {
              console.log('Websocket disconnected');
              this.wss = undefined;
              window.setTimeout(() => this.connect(), 1);
            }
          );
        }
        this.register();
      } else {
        if (this.wss) {
          console.log('No user session: closing websocket at ' + WSS_ROOT);
          // this.disconnect()
        }
      }
    } catch (err) {
      console.log('WebSocket general error: ' + err);
    }
    if (!this.wss) {
      window.setTimeout(() => this.connect(), 500);
    } else {
      console.log('Listening on ' + WSS_ROOT);
      this.ping();
    }
  }

  register() {
    if (this.wss && this.session && this.session.isValid()) {
      console.log('Registering user with ' + WSS_ROOT);
      this.wss.next({
        action: 'register',
        id: this.session.idToken.jwtToken,
        auth: this.session.accessToken.jwtToken,
      });
    }
  }

  ping() {
    if (this.wss && this.session && this.session.isValid()) {
      console.log('Pinging ' + WSS_ROOT);
      this.wss.next({
        action: 'ping',
        id: this.session.idToken.jwtToken,
      });
      window.setTimeout(() => this.ping(), 30000);
    }
  }

  subscribe(channel: string) {
    console.log('Subscribing to ' + channel)
    this.wss.next({
      action: 'subscribe',
      channel: channel,
    });
  }

  unSubscribe(channel: string) {
    this.wss.next({
      action: 'unsubscribe',
      channel: channel,
    });
  }
}
