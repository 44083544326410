<div class="reset-password-container" *ngIf="displayed">
  <form class="reset-password-form">
    <div class="alert alert-info">
      Please check your email for the verification code.
    </div>

    <div class="form-group">
      <!--      <label for="name">Username/email</label>-->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="name"
          type="text"
          data-lpignore="true"
          [formControl]="username"
          required
          name="name"
          placeholder="Username / email"
        />
        <div [hidden]="username.valid || username.pristine" class="alert alert-danger">
          Username/email is required
        </div>
      </div>
    </div>

    <div class="form-group">
      <!--      <label for="password1">New Password</label>-->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="password1"
          data-lpignore="true"
          [type]="showPassword ? 'text' : 'password'"
          [formControl]="password1"
          name="password1"
          required
          placeholder="New Password"
        />
        <div class="input-group-append">
          <button *ngIf="!showPassword" (click)="showPassword = true" [attr.aria-label]="'Show password'">
            <fa-icon [icon]="faEyeSlash" title="Show Password"></fa-icon>
          </button>
          <button *ngIf="showPassword" (click)="showPassword = false" [attr.aria-label]="'Hide password'">
            <fa-icon [icon]="faEye" title="Hide Password"></fa-icon>
          </button>
        </div>
      </div>
      <div [hidden]="password1.valid || password1.pristine" class="alert alert-danger">
        New password is required
      </div>
    </div>

    <div class="form-group">
      <!--      <label for="password2">Confirm Password</label>-->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="password2"
          data-lpignore="true"
          [type]="showPassword ? 'text' : 'password'"
          [formControl]="password2"
          name="password2"
          required
          placeholder="Confirm New Password"
        />
        <div class="input-group-append">
          <button *ngIf="!showPassword" (click)="showPassword = true" [attr.aria-label]="'Show password'">
            <fa-icon [icon]="faEyeSlash" title="Show Password"></fa-icon>
          </button>
          <button *ngIf="showPassword" (click)="showPassword = false" [attr.aria-label]="'Hide password'">
            <fa-icon [icon]="faEye" title="Hide Password"></fa-icon>
          </button>
        </div>
      </div>
      <div [hidden]="password2.valid || password2.pristine" class="alert alert-danger">
        Confirm password is required
      </div>
    </div>

    <div class="form-group">
      <!--      <label for="verification-code">Verification code</label>-->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="verification-code"
          type="text"
          data-lpignore="true"
          [formControl]="verificationCode"
          required
          placeholder="Verification Code"
          name="name"
        />
        <div [hidden]="verificationCode.valid || verificationCode.pristine" class="alert alert-danger">
          Verification code is required
        </div>
      </div>
    </div>

    <div [hidden]="!errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <button [disabled]="!canSaveNewPassword()" class="save-password-button" (click)="saveNewPassword()">
      <ng-container *ngIf="submitted">
        <span class="sign-in-progress spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
        Changing Password
      </ng-container>
      <ng-container *ngIf="!submitted">
        Change Password
      </ng-container>
    </button>
  </form>
</div>
