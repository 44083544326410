<div class="setup-mfa-container" *ngIf="displayed">
  <qrcode [qrdata]="qrCode" [width]="300" class="qr-code" [errorCorrectionLevel]="'M'"></qrcode>

  <form class="setup-mfa-form">
    <div class="alert alert-info">
      Scan the image above into an authenticator app (e.g. Google Authenticator) and enter the code below to verify the
      account.
    </div>
    <div class="form-group">
      <!--      <label for="totpVerificationCode">Verification Code</label>-->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="totpVerificationCode"
          type="text"
          [formControl]="verificationCode"
          required
          name="totpVerificationCode"
          placeholder="Verification Code"
        />
      </div>
    </div>
    <div [hidden]="!errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <button [disabled]="!canVerifyMfa()" class="verify-code-button" (click)="verifyMfa()">
      <ng-container *ngIf="submitted">
        <span class="sign-in-progress spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
        Verifying Code
      </ng-container>
      <ng-container *ngIf="!submitted">
        Verify Code
      </ng-container>
    </button>
  </form>
</div>
