<ng-container *ngIf="user">
  <ng-container *ngIf="devGuard.hasAccess | async">
    <a class="user-menu__developer-token" (click)="saveDeveloperToken()" title="Save Developer Token">
      <fa-icon [icon]="faKey"></fa-icon>
    </a>
  </ng-container>

  <div class="user-menu">
    <button class="user-menu__toggle" (click)="toggleMenu()">
      <div class="user-menu__username">
        {{ user }}
      </div>
      <app-user-icon></app-user-icon>
    </button>

    <div class="user-menu__dropdown" *ngIf="menuDisplayed">
      <button class="btn user-menu__sign-out-btn" (click)="signOut()">
        Sign Out
      </button>
    </div>
  </div>
</ng-container>
