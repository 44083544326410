import {Injectable} from '@angular/core'
import {Observable, of} from 'rxjs'
import {ApiService} from './api.service'
import {flatMap, map, switchMap, tap} from 'rxjs/operators'

const jwt = require('jsonwebtoken')

@Injectable({
  providedIn: 'root',
})
export class PermissionTokenService {   // TODO: add unit tests for this token cache

  private tokenCache: PermissionCache = {}

  constructor(
    private apiService: ApiService,
  ) {
  }


  private getTokenForPath(path: string[], method: string) {
    let tokenMapping = this.tokenCache
    for (const part of path) {
      const wcPath = tokenMapping['*']
      if (wcPath && (wcPath.method === 'ANY' || wcPath.method === method) && wcPath.expiresAt > new Date()) {
        return wcPath.token
      } else if (tokenMapping[part]) {
        tokenMapping = tokenMapping[part]
      } else {
        tokenMapping = undefined
        break
      }
    }

    const tokenElem = tokenMapping

    if (tokenElem && (tokenElem.method === '*' || tokenElem.method === method) && tokenElem.expiresAt < new Date()) {
      return tokenElem.token
    }

    return undefined
  }

  public getPermissionsToken(path: string, method: string): Observable<any> {
    // TODO: There should be a blocking mechanism in place to prevent multiple simultaneous requests to the permissions endpoint
    return of([path, method]).pipe(
      flatMap(([pathStr, methodStr]) => {
        if (pathStr === 'permissions/permissions' || pathStr === 'settings/authServers') {
          return of('TOKEN')
        }

        const pathParts = pathStr.split('/')
        const token = this.getTokenForPath(pathParts, methodStr) // FIXME: check why type is needed here....

        if (!token) {
          return this.apiService.get('permissions/permissions').pipe(
            switchMap((permissionList: PermissionList) => {
              if (!permissionList.permissions || permissionList.permissions.length === 0) {
                return of('ANONYMOUS')
              }
              return this.apiService.post('permissions/permissions', this.getBestMatchingPath(pathParts, methodStr, permissionList.permissions)).pipe(
                map((permission: PermissionResponse) => permission.permissionToken),
                tap(token => {
                  this.storeTokenInCache(token)
                }),
              )
            }),
          )
        } else {
          return of(token)
        }
      }),
    )
  }

  private getBestMatchingPath(path: string[], method: string, permissions: Permission[]) {
    let matchPath = ''
    let matchMethod = ''
    for (const permission of permissions) {
      const endpoint = permission.endpoint.split('/')
      if (permission.method === method || permission.method === 'ANY') {
        let index = 0
        while (index < path.length && index < endpoint.length && (path[index] === endpoint[index] || endpoint[index] === '*')) {
          index++
        }
        if (index === path.length || index === endpoint.length) {
          matchPath = permission.endpoint
          matchMethod = permission.method
        }
      }
    }
    return {
      endpoint: matchPath,
      method: matchMethod,
    }
  }

  private storeTokenInCache(token: string) {
    const decodedToken = jwt.decode(token)
    for (const permission of decodedToken.permissions) {
      if (permission.action === 'ALLOW') {
        let endpointParts = permission.endpoint.split('/')
        if (!endpointParts[0]) {
          endpointParts = endpointParts.splice(-1)
        }
        let cache = this.tokenCache
        for (const endpointPart of endpointParts) {
          if (!cache[endpointPart]) {
            cache[endpointPart] = {}
          }
          cache = cache[endpointPart]
        }
        if (!cache.expiresAt || cache.expiresAt < permission.expiresAt) {
          cache['method'] = permission.method
          cache['token'] = token
          cache['expiresAt'] = permission.expiresAt
        }
      }
    }
  }

}

interface PermissionResponse {
  permissionToken: string
}

interface Permission {
  endpoint: string
  method: string
}

interface PermissionList {
  permissions: Permission[]
}


interface PermissionCacheNode {
  [key: string]: PermissionCache
}

interface PermissionCacheEntry {
  method?: string
  token?: string
  expiresAt?: string
}

type PermissionCache = PermissionCacheNode | PermissionCacheEntry
