import {combineLatest, EMPTY, iif, merge, Observable, of, Subject} from 'rxjs'
import {ApiService} from './api.service'
import {
  bufferTime,
  debounceTime,
  filter,
  map,
  mergeMap,
  pairwise,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators'
import {ServerEventsService} from './server-events.service'
import {Identified} from '../model/identified'

const MAX_DATA_REFRESH = 800
const MAX_SINGLE_CALLS = 5

interface ServerSideEvent {
  event?: string
  key?: string
}

export class ReactiveApiService {
  protected cache$: Map<string, Observable<Array<any>>> = new Map()
  private reload$: Map<string, Subject<void>> = new Map()

  constructor(
    public apiRoot: string,
    public subscriptionKey: string,
    public apiService: ApiService,
    public serverEventsService: ServerEventsService,
  ) {
    console.log('Subscribing to ' + this.subscriptionKey)
    this.serverEventsService.subscribe(this.subscriptionKey)
  }

  apiEvents: Observable<ServerSideEvent> = this.serverEventsService.wss.pipe(
    filter(message => message.event?.startsWith(this.apiRoot + '-')),
    map(e => e as ServerSideEvent),
  )

  rootEvents = (type: string) => this.apiEvents.pipe(
    filter(message => message.key?.startsWith(type + '/')),
    tap(e => {
      console.log('Websocket event received for ' + type)
      console.dir(e)
    }),
  )

  entityDeletedEvents = (type: string) => this.rootEvents(type).pipe(
    filter(message => message.event?.startsWith(this.apiRoot + '-deleted')),
    tap(_ => console.log('An entity has been deleted for ' + type)),
    debounceTime(500),
    tap(_ => console.log('Triggering entity deleted behaviour for ' + type)),
  )

  entityAddedEvents = (type: string) => this.rootEvents(type).pipe(
    filter(message => message.event?.startsWith(this.apiRoot + '-created')),
    tap(_ => console.log('An entity has been added to ' + type)),
    debounceTime(500),
    tap(_ => console.log('Triggering entity added behaviour for ' + type)),
  )

  entityEvents = (type: string, key: string) => this.rootEvents(type).pipe(
    filter(message => message.key === key),
  )

  entityUpdateEvents = (type: string, key: string) => this.entityEvents(type, key).pipe(
    filter(message => message.event?.startsWith(this.apiRoot + '-updated')),
    tap(_ => console.log('An entity has been updated ' + key)),
    debounceTime(100),
    tap(_ => console.log('Triggering entity update behaviour for ' + key)),
  )

  rootEntityLoader = (type: string) => {
    return this.apiService.get(`${this.apiRoot}/${type}/all`).pipe(
      tap(_ => console.log('Loading all for ' + type)),
      shareReplay(1),
    )
  }

  entityLoader = (key: string) => {
    return this.apiService.get(`${this.apiRoot}/${key}`).pipe(
      tap(_ => console.log('Loading entity ' + key)),
      shareReplay(1),
    )
  }

  wsSubscription<T>(type: string): Observable<T[]> {
    let bucket = []
    const createdNotification: Observable<any> = this.serverEventsService.wss.pipe(
      filter(message => message.event),
      filter(message => {
        // console.log('Checking if "' + message.event + '" starts with "' + this.apiRoot + '"')
        return message.event.startsWith(this.apiRoot)
      }),
      filter(message => message.key.startsWith(type)),
      bufferTime(MAX_DATA_REFRESH),
      startWith([]),
      switchMap(messages => {
        if (messages.some(message => message.event.endsWith('deleted'))) {
          bucket = new Array(MAX_SINGLE_CALLS + 1)
        }
        if (bucket.length <= MAX_SINGLE_CALLS) {
          bucket = bucket.concat(...messages)
        }
        return of(messages)
      }),
      pairwise(),
      // We might need to check if a certain amount of time passed, and if so, let the bucket go (so data gets a partial refresh sooner)
      switchMap(([prev, curr]) => (curr.length === 0 && prev.length === 0) ? of(bucket) : EMPTY),
      tap(() => bucket = []),
      mergeMap((messages) =>
        iif(
          () => (messages.length <= MAX_SINGLE_CALLS),
          combineLatest(messages.map(message => this.apiService.get<T>(`${this.apiRoot}/${message.key}`))),
          this.apiService.get<T>(`${this.apiRoot}/${type}/all`),
        ),
      ),
      switchMap(messages => {
        return of(...messages).pipe(toArray())
      }),
    )
    return createdNotification as Observable<T[]>
  }

  all<T>(type: string): Observable<T[]> {
    if (!this.cache$.has(type)) {
      const reloadSubject = new Subject<void>()
      this.reload$.set(type, reloadSubject)

      const rootLoader = merge(of(true), reloadSubject, this.entityDeletedEvents(type), this.entityAddedEvents(type))
        .pipe(
          switchMap(_ => {
            console.log(`Creating root loader for ${type}`)
            return this.rootEntityLoader(type)
          }),
          tap(d => {
            console.log('Loaded: ' + type)
            console.dir(d)
          }),
          shareReplay(1),
        )

      this.cache$.set(type, rootLoader as Observable<T[]>)
    }
    return this.cache$.get(type)
  }

  keys<T>(type: string): Observable<T[]> {
    const cacheKey = `${type}/keys`
    if (!this.cache$.has(cacheKey)) {
      this.reload$.set(type, new Subject<void>())
      const value = combineLatest([this.wsSubscription<T>(type).pipe(startWith([])), this.apiService.get<T[]>(`${this.apiRoot}/${type}/keys`)]).pipe(
        scan((all, [wsResponse, initialResponse]) =>
            wsResponse.length <= MAX_SINGLE_CALLS ? this.updateArrays(all.length > 0 ? all : initialResponse, wsResponse) : wsResponse,
          []),
        shareReplay(1),
      )

      this.cache$.set(cacheKey, value as Observable<T[]>)
    }
    return this.cache$.get(cacheKey)
  }

  updateArrays(long: any[], short: any[]) {
    for (const shortElem of short) {
      let updated = false
      for (let i = 0; i < long.length; i++) {
        if (long[i].key === shortElem.key) {
          updated = true
          long[i] = shortElem
        }
      }
      if (!updated) { // FIXME: Ugly hack
        long = long.concat(shortElem)
      }
    }
    return long
  }

  get<T extends Identified>(type: string, key: string): Observable<T> {
    let keyToFind = key
    if (keyToFind && !keyToFind.startsWith(type)) {
      keyToFind = type + '/' + key
    }
    return this.all<T>(type).pipe(
      switchMap(datas => {
        // return this.entityUpdateEvents(type, key)).pipe(
        //   startWith()
        // )
        // console.log('Looking for ' + keyToFind + ' in')
        // console.dir(datas)
        const match = datas.find(data => {
          return data && data.key && key && (data.key === keyToFind)
        })
        // console.log('Found: ')
        // console.dir(match)
        return match ? of(match) : of(undefined)
      }),
    )
  }

  waitFor<T extends Identified>(type: string, key: string): Observable<T> {
    return this.all<T>(type).pipe(
      switchMap(datas => {
        return of(...datas)
      }),
      filter(data => {
        return data.key === key
      }),
    )
  }

  create<T, R extends T>(type: string, payload: T): Observable<R> {
    return this.apiService.post(`${this.apiRoot}/${type}/new`, payload)
  }

  replace<T, R extends T>(key: string, payload: T): Observable<R> {
    return this.apiService.put(`${this.apiRoot}/${key}`, payload)
  }

  update<T, R extends T>(key: string, payload: any): Observable<R> {
    return this.apiService.patch(`${this.apiRoot}/${key}`, payload)
  }

  delete<T>(key: string): Observable<T> {
    return this.apiService.delete(`${this.apiRoot}/${key}`)
  }

  deleteAll<T>(type: string): Observable<T> {
    return this.apiService.delete(`${this.apiRoot}/${type}/all`)
  }

  deleteAllData<T>(): Observable<T> {
    return this.apiService.delete(`${this.apiRoot}/all`)
  }

  forceReload(type: string) {
    this.cache$.delete(type)
    this.cache$.delete(`${type}/keys`)
    this.cache$.delete(`${type}/metadata`)
    this.cache$.delete(`${type}/versions`)
    this.reload$.get(type)?.next()
  }

  invalidateCache() {
    this.cache$.clear()
  }
}
