import {Component, OnInit} from '@angular/core'
import {AdminGuard} from '../../guards/admin-guard.service'
import {ConnectGuard} from '../../guards/connect-guard.service'
import {ClientReportingGuard} from '../../guards/clientReporting-guard.service'
import {ChangeManagementGuard} from '../../guards/change-management-guard.service'
import {KydGuard} from '../../guards/kyd-guard.service'
import {PortalAdminGuard} from '../../guards/portal-admin-guard.service'

@Component({
  selector: 'app-internal-navigation',
  templateUrl: './internal-navigation.component.html',
  styleUrls: ['./internal-navigation.component.scss'],
})
export class InternalNavigationComponent implements OnInit {
  constructor(
    public portalAdminGuard: PortalAdminGuard,
    public adminGuard: AdminGuard,
    public connectGuard: ConnectGuard,
    public kydGuard: KydGuard,
    public clientReportingGuard: ClientReportingGuard,
    public changeManagementGuard: ChangeManagementGuard,
  ) {
  }

  ngOnInit(): void {
  }
}
