import {Component, Injector, OnDestroy, OnInit} from '@angular/core'
import {OverlayContainer} from '@angular/cdk/overlay'
import {ActivationEnd, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router'

import {AuthService, UserSession} from './auth/services/auth.service'
import {DateTime} from 'luxon'
import {DataService} from './services/data.service'
import {DocumentService} from './services/document-service'
import {TranslateService} from '@ngx-translate/core'
import defaultLanguage from '../assets/i18n/en.json'
import {map, switchMap} from 'rxjs/operators'
import {BehaviorSubject, of} from 'rxjs'
import {ApiService} from './services/api.service'
import {Client} from './model/client'
import {CurrentUserService} from './services/current-user.service'
import {ToastrService} from 'ngx-toastr'
import {ClipboardService} from 'ngx-clipboard'
import {faKey} from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loading = true
  navigating = false
  user: string = undefined
  userLocale = 'en'
  activeRoute: string

  appVersion = 'APP_VERSION_PLACEHOLDER'
  appBuildNumber = 'APP_BUILD_NUMBER_PLACEHOLDER'
  appBuildTime = parseInt('APP_BUILD_TIME_PLACEHOLDER', 10) || 1584435059600
  environment = ''

  appBuildDateString: string
  userType = ''
  faKey = faKey

  private organisation$ = new BehaviorSubject<string>(undefined)
  clientLogo = this.organisation$.asObservable().pipe(
    switchMap((client) => {
      if (!client) {
        return of('')
      }
      return this.apiService.get<Client>('data/' + client).pipe(map((client) => client.logo))
    }),
  )

  private session: UserSession

  constructor(
    private router: Router,
    private overlayContainer: OverlayContainer,
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private apiService: ApiService,
    private injector: Injector,
    private translate: TranslateService,
    private clipboard: ClipboardService,
    private toast: ToastrService,
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })

    try {
      if (this.appVersion.startsWith('APP_')) {
        this.appVersion = 'DEV-BUILD'
      }
      if (this.appBuildNumber.startsWith('APP_')) {
        this.appBuildNumber = 'DEV'
      }
      const dateTime = DateTime.fromMillis(this.appBuildTime)
      this.appBuildDateString = dateTime.toLocaleString({
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      })
      // this.authService.oauthToken.subscribe(token => this.token = token)
    } catch (e) {
      console.error('Failed to determine application version', e)
    }
  }

  ngOnInit() {
    // this.authService.onUserSession.subscribe((value) => {
    //   this.eastereggService.get().subscribe();
    // });
    this.currentUserService.currentUser.subscribe((user) => {
      this.user = user?.name

      this.userType = user?.type || 'unknown'

      this.organisation$.next(user?.organisation)
    })
    this.authService.onUserSession.subscribe((userSession) => {
      this.session = userSession
      this.environment = userSession?.environment || ''
      this.translate.addLangs(['en', 'de'])
      this.translate.setTranslation('en', defaultLanguage)
      this.translate.setDefaultLang(this.userLocale && this.userLocale.match(/en|de/) ? this.userLocale : 'en') // TODO: retrieve user locale from user object
    })
  }

  ngOnDestroy(): void {
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.navigating = true
      this.activeRoute = undefined
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.navigating = false
    }
    if (event instanceof ActivationEnd) {
      this.activeRoute = event.snapshot.routeConfig.path
    }

    // if (event instanceof NavigationEnd) {
    //   console.log('NAV: ' + event.url + '  ' + event.toString())
    // }
  }

  signOut() {
    this.authService.signOut().then(() => {
      this.injector.get(DataService).invalidateCache()
      this.injector.get(DocumentService).invalidateCache()
      this.router.navigate(['/'])
    })
  }

  saveDeveloperToken() {
    console.log('Saving developer token to clipboard')
    const data = {
      id: this.session.idToken.jwtToken,
      access: this.session.accessToken.jwtToken,
    }
    this.clipboard.copy('const jwtTokens = ' + JSON.stringify(data, undefined, 2))
    this.toast.info('JWT Tokens copied to the clipboard!')
  }
}
