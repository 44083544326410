import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core'
import {FormControl, Validators} from '@angular/forms'
import {faEyeSlash} from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye'
import {AuthService} from '../../services/auth.service'
import {Subscription} from 'rxjs'

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginFormComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  displayed: boolean = false
  submitted: boolean = false

  faEyeSlash = faEyeSlash
  faEye = faEye

  username = new FormControl('', [Validators.required])
  password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(64)])

  showPassword: boolean
  errorMessage: string
  infoMessage: string

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.sub.add(
      this.authService.onLoginRequired.subscribe(loginRequired => {
        this.displayed = loginRequired
        this.submitted = false
      }),
    )
    this.sub.add(
      this.authService.onAuthError.subscribe(err => {
        this.errorMessage = err && err.message
        this.submitted = false
      }),
    )
    this.sub.add(
      this.authService.onAuthInfo.subscribe(info => {
        this.infoMessage = info
        this.submitted = false
      }),
    )

    this.username.reset()
    this.sub.add(
      this.username.valueChanges.subscribe(() => {
        this.errorMessage = undefined
        this.infoMessage = undefined
      }),
    )

    this.password.reset()
    this.sub.add(
      this.password.valueChanges.subscribe(() => {
        this.errorMessage = undefined
        this.infoMessage = undefined
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  canLogin() {
    return (!this.submitted && this.username.valid && this.password.valid)
  }

  login() {
    this.submitted = true
    this.authService.login(this.username.value, this.password.value)
  }

  forgotPassword() {
    this.authService.forgotPassword(this.username.value)
  }
}
