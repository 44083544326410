import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {FormControl, Validators} from '@angular/forms'
import {AuthService} from '../../services/auth.service'
import {faEyeSlash} from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye'

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  displayed: boolean = false
  submitted: boolean = false

  faEyeSlash = faEyeSlash
  faEye = faEye

  username = new FormControl('', [Validators.required])
  password1 = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(64)])
  password2 = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(64)])
  verificationCode = new FormControl('', [Validators.required])

  showPassword: boolean
  errorMessage: string

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.sub.add(
      this.authService.onResetPasswordRequired.subscribe(resetPasswordNeeded => {
        this.displayed = resetPasswordNeeded
        this.submitted = false
      }),
    )
    this.sub.add(
      this.authService.onAuthError.subscribe(err => {
        this.errorMessage = err && err.message
        this.submitted = false
      }),
    )

    this.username.reset()
    this.sub.add(
      this.username.valueChanges.subscribe(() => {
        this.errorMessage = undefined
      }),
    )

    this.password1.reset()
    this.sub.add(
      this.password1.valueChanges.subscribe(() => {
        this.errorMessage = undefined
      }),
    )

    this.password2.reset()
    this.sub.add(
      this.password2.valueChanges.subscribe(() => {
        this.errorMessage = undefined
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  canSaveNewPassword() {
    return (!this.submitted && this.username.valid && this.password1.valid && this.password2.valid && this.verificationCode.valid)
  }

  saveNewPassword() {
    if (this.password1.value !== this.password2.value) {
      this.errorMessage = 'Passwords don\'t match'
    } else {
      this.authService.resetPassword(this.username.value, this.password1.value, this.verificationCode.value)
    }
  }
}
