import {Injectable} from '@angular/core'
import {merge, Observable, of} from 'rxjs'
import {ApiService} from './api.service'
import {filter, shareReplay, switchMap} from 'rxjs/operators'
import {ServerEventsService} from './server-events.service'
import {DocumentVersion} from '../model/document-version'
import {ReactiveApiService} from './reactive.api.service'

const API_SLUG = 'documents'

const EVENT_SOURCE = 'inspire.PlatformDocs'

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends ReactiveApiService {
  constructor(
    public apiService: ApiService,
    public serverEventsService: ServerEventsService,
  ) {
    super(API_SLUG, EVENT_SOURCE, apiService, serverEventsService)
    this.serverEventsService.subscribe('inspire.PlatformData')
  }

  postFile(path, headers, file): Observable<DocumentVersion> {
    return this.apiService.postFile(path, headers, file)
  }

  deleteFile(key) : Observable<any> {
    return this.apiService.delete(`${API_SLUG}/${key}`)
  }

  getPdfDocument(path: string, version?: string): Observable<Blob> {
    return this.apiService.getDocument(path, 'application/pdf', version)
  }

  approve(key: string, payload): Observable<any> {
    return this.apiService.post(`${API_SLUG}/${key}`, payload)
  }

  publish(key: string, version: string, to: string): Observable<any> {
    return this.apiService.post(`${API_SLUG}/${key}/publish?version=${version}&to=${to}`, '')
  }

  versions(key): Observable<Array<any>> {
    const cacheKey = `${key}/versions`
    if (!this.cache$.has(cacheKey)) {

      const createdNotification = this.serverEventsService.wss.pipe(
        filter(message => message.event),
        filter(message => message.event.startsWith('document')),
        filter(message => {
          return message.key === key
        }),
      )

      const value = merge(of(true), createdNotification).pipe(
        switchMap(_ => this.apiService.get<Array<any>>(`${API_SLUG}/${key}/versions`)),
        shareReplay(1),
      )

      this.cache$.set(cacheKey, value)
    }
    return this.cache$.get(cacheKey)
  }

  metadata<T>(key, version?): Observable<T> {
    let path = `${API_SLUG}/${key}/metadata`;
    if (version) {
      path += `?version=${version}`
    }
    return this.apiService.get(path)
  }
}
