import {Component, OnInit} from '@angular/core'
import {ClientReportingGuard} from '../../guards/clientReporting-guard.service'
import {ChangeManagementGuard} from '../../guards/change-management-guard.service'
import {ConnectGuard} from '../../guards/connect-guard.service'
import {KydGuard} from '../../guards/kyd-guard.service'

@Component({
  selector: 'app-client',
  templateUrl: './client-navigation.component.html',
  styleUrls: ['./client-navigation.component.scss'],
})
export class ClientNavigationComponent implements OnInit {
  constructor(
    public clientReportingGuard: ClientReportingGuard,
    public changeManagementGuard: ChangeManagementGuard,
    public connectGuard: ConnectGuard,
    public kydGuard: KydGuard,
  ) {
  }

  ngOnInit(): void {
  }
}
