<div class="confirm-signin-container" *ngIf="displayed">
  <form class="confirm-signin-form">
    <div class="alert alert-info">
      Please enter your MFA verification code below.
    </div>
    <div class="form-group">
      <!--      <label for="totpVerificationCode">Verification Code</label>-->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="totpVerificationCode"
          type="text"
          [formControl]="verificationCode"
          required
          placeholder="Verification Code"
          name="totpVerificationCode"
        />
      </div>
    </div>
    <div [hidden]="!errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <button [disabled]="!canConfirmSignIn()" class="confirm-signin-button" (click)="confirmSignIn()">
      <ng-container *ngIf="submitted">
        <span class="sign-in-progress spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
        Verifying Code
      </ng-container>
      <ng-container *ngIf="!submitted">
        Verify Code
      </ng-container>
    </button>
  </form>
</div>
