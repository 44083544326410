<nav [className]="open ? 'navbar open' :'navbar'">
      <span id="left-wrapper">
        <div id="burger-menu" (click)="showMenu()" [className]="open ? 'open' : ''">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <app-navbar-logo></app-navbar-logo>
      </span>
  <ng-content></ng-content>
</nav>
