import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ClientLogoComponent} from './client-logo.component'
import {CreditSuisseLogo} from './credit-suisse-logo'
import {HsbcLogo} from './hsbc-logo'
import {BankOfAmericaLogo} from './bank-of-america-logo'
import {BarclaysLogo} from './barclays-logo'
import {BnpParibasLogo} from './bnp-paribas-logo'
import {DeutscheBankLogo} from './deutsche-bank-logo'
import {MorganStanleyLogo} from './morgan-stanley-logo'
import {SocieteGeneraleLogo} from './societe-generale-logo'
import {UbsLogo} from './ubs-logo'
import {ClientLogoSelectorComponent} from './client-logo-selector.component'
import {JpmorganLogo} from './jpmorgan-logo'

@NgModule({
  declarations: [
    ClientLogoComponent,
    ClientLogoSelectorComponent,
    BankOfAmericaLogo,
    BarclaysLogo,
    BnpParibasLogo,
    CreditSuisseLogo,
    DeutscheBankLogo,
    HsbcLogo,
    JpmorganLogo,
    MorganStanleyLogo,
    SocieteGeneraleLogo,
    UbsLogo,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ClientLogoComponent,
    ClientLogoSelectorComponent,
  ],
})
export class ClientLogosModule {
}
