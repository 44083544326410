import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {ConnectGuard} from './guards/connect-guard.service'
import {DashboardComponent} from './views/dashboard.component'
import {PageNotFoundComponent} from './views/page-not-found/page-not-found.component'
import {ClientReportingGuard} from './guards/clientReporting-guard.service'
import {ChangeManagementGuard} from './guards/change-management-guard.service'
import {KydGuard} from './guards/kyd-guard.service'
import {PortalAdminGuard} from './guards/portal-admin-guard.service'

const routes: Routes = [
  {path: '', pathMatch: 'full', component: DashboardComponent},

  // CLIENT PORTAL CONFIGURATION MODULES
  {
    path: 'admin',
    loadChildren: () => import('./portal-admin/portal-admin.module').then((m) => m.PortalAdminModule),
    // canLoad: [PortalAdminGuard],
    canActivate: [PortalAdminGuard],
  },

  // CLIENT MODULES
  {
    path: 'dashboard',
    loadChildren: () => import('./client/client-dashboard/client-dashboard.module').then((m) => m.ClientDashboardModule),
    // canLoad: [ClientReportingGuard],
    // canActivate: [ClientReportingGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./client/client-reports/client-reports.module').then((m) => m.ClientReportsModule),
    canLoad: [ClientReportingGuard],
    canActivate: [ClientReportingGuard],
  },
  {
    path: 'stats',
    loadChildren: () => import('./client/client-stats/client-stats.module').then((m) => m.ClientStatsModule),
    canLoad: [ClientReportingGuard],
    canActivate: [ClientReportingGuard],
  },

  // DEMO MODULES
  {
    path: 'connectivity',
    loadChildren: () => import('./demo/connectivity/connectivity.module').then((m) => m.ConnectivityModule),
    canLoad: [ConnectGuard],
    canActivate: [ConnectGuard],
  },
  {
    path: 'kyd',
    loadChildren: () => import('./demo/issuer-kyd/issuer-mvp.module').then((m) => m.IssuerMvpModule),
    canLoad: [KydGuard],
    canActivate: [KydGuard],
  },
  {
    path: 'changes',
    loadChildren: () => import('./demo/client-change-management/client-change-management.module').then((m) => m.ClientChangeManagementModule),
    canLoad: [ChangeManagementGuard],
    canActivate: [ChangeManagementGuard],
  },

  {path: '**', component: PageNotFoundComponent},
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
