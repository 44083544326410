import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {NavbarComponent} from './navbar/navbar.component'
import {NavbarLogoComponent} from './navbar-logo/navbar-logo.component'
import {NavbarNavListComponent} from './navbar-nav-list/navbar-nav-list.component'
import {NavbarNavItemComponent} from './navbar-nav-item/navbar-nav-item.component'
import {RouterModule} from '@angular/router'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarLogoComponent,
    NavbarNavListComponent,
    NavbarNavItemComponent,
  ],
    imports: [CommonModule, RouterModule, FontAwesomeModule],
  exports: [
    NavbarComponent,
    NavbarLogoComponent,
    NavbarNavListComponent,
    NavbarNavItemComponent,
  ],
})
export class NavigationModule {}
