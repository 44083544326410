<div class="new-password-container" *ngIf="displayed">
  <form class="new-password-form">
    <div class="form-group">
           <!-- <label for="name" class="label">Your Name</label> -->
      <div class="input-group input-group-lg">
      
        <input
          class="form-control form-control-lg"
          id="name"
          type="text"
          [formControl]="name"
          required
          name="name"
          placeholder="Your Name"
        />
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Your name is required
        </div>
      </div>
    </div>
    <div class="form-group">
           <!-- <label for="password1" class="label">New Password</label> -->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="password1"
          data-lpignore="true"
          [type]="showPassword ? 'text' : 'password'"
          [formControl]="password1"
          name="password1"
          required
          placeholder="New Password"
        />
        <div class="input-group-append">
          <button *ngIf="!showPassword" (click)="showPassword = true" [attr.aria-label]="'Show password'">
            <fa-icon [icon]="faEyeSlash" title="Show Password"></fa-icon>
          </button>
          <button *ngIf="showPassword" (click)="showPassword = false" [attr.aria-label]="'Hide password'">
            <fa-icon [icon]="faEye" title="Hide Password"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group">
           <!-- <label for="password2" class="label">Repeat New Password</label> -->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="password2"
          data-lpignore="true"
          [type]="showPassword ? 'text' : 'password'"
          [formControl]="password2"
          name="password2"
          required
          placeholder="Repeat New Password"
        />
        <div class="input-group-append">
          <button *ngIf="!showPassword" (click)="showPassword = true" [attr.aria-label]="'Show password'">
            <fa-icon [icon]="faEyeSlash" title="Show Password"></fa-icon>
          </button>
          <button *ngIf="showPassword" (click)="showPassword = false" [attr.aria-label]="'Hide password'">
            <fa-icon [icon]="faEye" title="Hide Password"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div [hidden]="!errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <button [disabled]="!canSaveNewPassword()" class="save-password-button" (click)="saveNewPassword()">
      <ng-container *ngIf="submitted">
        <span class="sign-in-progress spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
        Changing Password
      </ng-container>
      <ng-container *ngIf="!submitted">
        Change Password
      </ng-container>
    </button>
  </form>
</div>
