import {Injectable} from '@angular/core'
import {AuthService} from '../auth/services/auth.service'
import {SectionGuard} from './section-guard.service'

@Injectable({
  providedIn: 'root',
})
export class DevGuard extends SectionGuard {
  constructor(authService: AuthService) {
    super(authService, ['Developer'])
  }
}
