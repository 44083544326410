import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router'
import deepEqual from 'deep-equal'
import {Observable} from 'rxjs'
import {distinctUntilChanged, map, shareReplay} from 'rxjs/operators'
import {AuthService, UserSession} from '../auth/services/auth.service'

const groupCheck = (session: UserSession, allowedGroups: string[]) => {
  const groups: string[] = (session?.accessToken?.payload?.['cognito:groups']) || []
  return session && session.isValid() && groups.some(group => allowedGroups.includes(group)) // && false
}

export class SectionGuard implements CanActivate, CanLoad {
  hasAccess: Observable<boolean>

  constructor(private authService: AuthService, groups: string[]) {
    this.hasAccess = authService.onUserSession.pipe(
      map(session => {
        return groupCheck(session, groups)
      }),
      distinctUntilChanged(deepEqual),
      shareReplay(1)
    )
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccess
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true
  }
}
