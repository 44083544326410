import {Component, OnInit} from '@angular/core'
import {faCross} from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-navbar-nav-list',
  styleUrls: ['./navbar-nav-list.component.scss'],
  templateUrl: './navbar-nav-list.component.html'
})
export class NavbarNavListComponent implements OnInit {

  faCross = faCross

  ngOnInit(): void {
    //
  }

  closeMenu() {
    //
  }
}
