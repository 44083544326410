import {Component, OnInit} from '@angular/core'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {NavigationEnd, Router} from '@angular/router'

@Component({
  selector: 'app-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  constructor(private router: Router) {
    router.events.subscribe( (val) => {
      if (val instanceof NavigationEnd) {
        this.open = false;
      }
    })
  }

  faBars = faBars

  open = false

  ngOnInit(): void {}

  showMenu() {
    this.open = !this.open;
  }
}
