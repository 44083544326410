import {Component, OnInit} from '@angular/core'

@Component({
  selector: 'app-navbar-logo',
  styleUrls: ['./navbar-logo.component.scss'],
  templateUrl: './navbar-logo.component.html',
})
export class NavbarLogoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
