import {Component, OnInit} from '@angular/core'
import {CurrentUserService} from '../services/current-user.service'
import {Router} from '@angular/router'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private router: Router,
    private currentUserService: CurrentUserService) {
  }

  ngOnInit() {
    this.currentUserService.currentUser.subscribe((user) => {
      if (user.type === 'client') {
        this.router.navigateByUrl('/dashboard')
      }
    })
  }
}
