<app-navbar>
  <app-navbar-nav-list>
    <app-navbar-nav-item to="dashboard">
      Dashboard
    </app-navbar-nav-item>

    <app-navbar-nav-item *ngIf="clientReportingGuard.hasAccess | async" to="reports">
      Reports
    </app-navbar-nav-item>

    <app-navbar-nav-item *ngIf="clientReportingGuard.hasAccess | async" to="stats">
      Stats
    </app-navbar-nav-item>

<!--    <app-navbar-nav-item *ngIf="kydGuard.hasAccess | async" to="kyd">-->
<!--      {{ 'CLIENT.ISSUER-KYD' | translate }}-->
<!--    </app-navbar-nav-item>-->

<!--    <app-navbar-nav-item *ngIf="connectGuard.hasAccess | async" to="connectivity">-->
<!--      Connectivity-->
<!--    </app-navbar-nav-item>-->

<!--    <app-navbar-nav-item *ngIf="changeManagementGuard.hasAccess | async" to="changes">-->
<!--      Change Management-->
<!--    </app-navbar-nav-item>-->
  </app-navbar-nav-list>

  <app-user-menu></app-user-menu>
</app-navbar>

<router-outlet></router-outlet>
